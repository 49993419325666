/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Payroc API uses Bearer tokens to authenticate requests. To generate a Bearer token, include your API token in the 'x-api-key' parameter in the header of a request to the Payroc Identity Service."), "\n", React.createElement(_components.h2, null, "API key best practices"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Grant API keys with the least amount of privilege to carry out target tasks."), "\n", React.createElement(_components.li, null, "Do not share API keys."), "\n", React.createElement(_components.li, null, "Do not use API keys in publicly accessible areas, for example, client-side code."), "\n"), "\n", React.createElement(Admonition, {
    type: "important"
  }, React.createElement(_components.p, null, "Use HTTPS for all requests to the Payroc API. We reject all HTTP requests, and all requests that are not properly authenticated.")), "\n", React.createElement(_components.h2, null, "Request"), "\n", React.createElement(_components.p, null, "Identity Service endpoint: ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n")), "\n", React.createElement(_components.h2, null, "Response"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"access_token\": \"eyJhbGc....adQssw5c\",\r\n  \"expires_in\": 3600,\r\n  \"scope\": \"service_a service_b\",\r\n  \"token_type\": \"Bearer\"\r\n}\n")), "\n", React.createElement(_components.p, null, "Include the access_token as a Bearer token in all future requests to the Payroc API, for example:"), "\n", React.createElement(_components.h2, null, "Request"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\r\n-H \"Authorization: Bearer <access token>\"\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
